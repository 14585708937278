.demo-ruleForm {
  position: relative;
}
.demo-ruleForm .ckbjmx {
  position: absolute;
  left: 900px;
}
.demo-ruleForm .el-form-item {
  width: 520px;
}
.demo-ruleForm .ts .el-form-item__label {
  font-size: 17px;
  font-weight: 600;
}
.demo-ruleForm .btbzWitch {
  min-width: 100%;
}
.demo-ruleForm .btbzWitch .el-table__header-wrapper th {
  padding: 0;
  background: #5c6be8;
  color: #fff;
  border-color: #5c6be8;
}
.demo-ruleForm .tsWidth {
  width: 550px;
}
.demo-ruleForm .tsWidth .el-form-item__content {
  display: flex;
}
.demo-ruleForm .tsWidth .el-form-item__content .el-button {
  margin-left: 0.75rem;
}
.demo-ruleForm .sfbzWidth {
  min-width: 1100px;
}
.demo-ruleForm .operation {
  width: 800px;
}
.demo-ruleForm .operation .el-button {
  width: 100px;
  margin: 0 15px;
}
.demo-ruleForm .tb-edit .el-input {
  display: none;
}
.demo-ruleForm .tb-edit .current-row .el-input {
  display: block;
}
.demo-ruleForm .tb-edit .current-row .el-input + span {
  display: none;
}
.demo-ruleForm /deep/ .el-textarea .el-textarea__inner {
  height: 100%;
}
.demo-ruleForm /deep/ .el-textarea .el-input__count {
  line-height: 15px !important;
}
